<template>
  <div class="h-100-vh">
    <div v-if="!loading.store && !storeAlreadyIntegrated">
      <div class="card card-ecart ">
        <div class="card-header">
          <div class="row">
            <div class="col-9">
              <h5 class="text-truncate">
                <span v-text=" integration? $t('general.newStore') : $t('general.form.store') "></span>
                <small>
                  <strong> - </strong>
                  <strong :class=" storeInformation.active ? 'text-success' : 'text-muted' "
                    v-text=" (storeInformation.active ? $t('general.online'): $t('general.offline') ) ">
                  </strong>
                </small>
              </h5>
            </div>
            <div class="col-auto ml-auto " v-show=" !integration ">
              <div class="media-body switch ">
                <label class="switch">
                  <input type="checkbox" v-model="storeInformation.active"><span class="switch-state "
                    :class="  storeInformation.active ? 'bg-success': '' " @click=" fnApiChangeStatusStore() "></span>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body">

          <div class="row">
            <div :class="integration ? 'col-12' : 'col-12 col-xl-8'">
              <!-- :class=" integration ? '' : 'border-top-0' " -->
              <b-tabs :content-class=" integration ? '' : 'border-left border-bottom border-right p-3'  "
                :nav-class='integration ? "d-none" : null '>
                <b-tab :title="$t('general.form.information')" active>
                  <StoreInformation />
                </b-tab>
                <b-tab :title="$t('general.form.addresses')">
                  <StoreAddresses />
                </b-tab>
              </b-tabs>
            </div>
            <div class="col-12 col-xl-4 mt-2 mt-md-3 mt-xl-0 " v-if="!integration">
              <StoreStats />
            </div>
          </div>

        </div>
      </div>
      <StoreDomains v-if="!integration" />
      <!-- <StoreWebsite v-if="!integration" /> -->

    </div>
    <StoreAlreadyIntegrated v-if=" storeAlreadyIntegrated " />
    <no-data :isLoading="loading.store " v-if="loading.store" :dataMessage="$t('noDataMessages.errorDefault')"
      :showBtn="false" class=" "></no-data>
    <StoreAddressModal />
  </div>

</template>

<script>
  import {
    mapActions,
    mapMutations,
    mapState
  } from 'vuex';
  import StoreInformation from "./components/store-information";
  import StoreAddresses from "./components/store-addresses";
  import StoreAlreadyIntegrated from "./components/store-already-integrated";
  import StoreAddressModal from "./components/store-address-modal";
  import StoreStats from "./components/store-stats";
  import StoreDomains from "./components/store-domains.vue";
  import StoreWebsite from "./components/store-website.vue";

  export default {
    components: {
      StoreInformation,
      StoreAddresses,
      StoreAddressModal,
      StoreAlreadyIntegrated,
      StoreStats,
      StoreDomains,
      StoreWebsite,
    },
    computed: {
      ...mapState('EcartStore', ['storeInformation', 'addressesList', 'loading',
        'storeAlreadyIntegrated', 'integration'
      ]),
      ...mapState('session', ['user']),
      // ...mapState(['integration']),
    },
    methods: {
      ...mapMutations('EcartStore', ['resetState', 'setIntegration']),
      ...mapMutations('menu', ['setStepNumber']),
      ...mapActions('EcartStore', ['fnApiUpdateSellerStore', 'fnApiChangeStatusStore', 'fnApiGetStoreStats']),
      // ...mapMutations(['setIntegration']),

      fnPreloadStoreInfo() {
        this.storeInformation.email = this.user.email || null;
        this.storeInformation.phone = this.user.phone || null;
      },
    },
    beforeMount() {
      if (this.$route.query.integration) {
        this.setIntegration(true);
      }
      if (this.user.complete == false) {
        this.setStepNumber(3);
      }
    },
    async mounted() {
      await this.fnApiUpdateSellerStore(this.$route.params.id);
      if (this.$route.query.integration) {
        this.fnPreloadStoreInfo()
      } else {
        this.fnApiGetStoreStats();
      }
    },
    beforeDestroy() {
      this.resetState()
    }
  }
</script>

<style>

</style>