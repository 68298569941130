<template>
  <div>
    <div class="card" >
      <div class="card-body text-center h-100-vh d-flex align-items-center flex-column justify-content-center ">
        <p class="f-24" v-text="$t('alertMessages.storeAlreadyIntegrated')"> </p>
        <p>
          <span v-text="$t('tables.store') + ': ' "> </span>
          <strong v-text="this.$route.params.id"></strong>
        </p>
        <p>
          <router-link to="/dashboard/stores">
            <strong v-text="$t('dashboard.welcome.goTo') + ' ' + $t('dashboard.menu.stores').toLowerCase() "></strong>
          </router-link>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>