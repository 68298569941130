<template>
  <div>

    <div class="card">
      <div class="card-body">
        <div class="row align-items-center">
          <div class="col">
            <h6 class="m-0">
              <span v-text="$t('dashboard.store.orders')"></span>
              <span
                v-text=" ' (' + ((storeInformation.stadistics.orders.sales + storeInformation.stadistics.orders.pending + storeInformation.stadistics.orders.cancelled) || 0 ) + ')' "></span>
            </h6>
          </div>
          <div class="col-auto">
            <button class="btn btn-outline-success circle-25" @click="showOrderStats = !showOrderStats">
              <i class="fa fa-eye fa-sm" v-if="!showOrderStats"></i>
              <i class="fa fa-eye-slash fa-sm" v-else></i>
            </button>
          </div>
        </div>

        <div class="animated fadeIn" v-show="showOrderStats">
          <table class="table table-striped table-bordered mt-2">
            <tbody>
              <tr>
                <td class="col" v-text="$t('tables.sales')"> </td>
                <td class="text-center" v-text=" storeInformation.stadistics.orders.sales || 0 "></td>
              </tr>
              <tr>
                <td v-text="$t('dashboard.store.ordersStatus.pending')"> </td>
                <td class="text-center" v-text=" storeInformation.stadistics.orders.pending || 0 "></td>
              </tr>
              <tr>
                <td v-text="$t('dashboard.store.ordersStatus.cancelled')"> </td>
                <td class="text-center" v-text=" storeInformation.stadistics.orders.cancelled || 0 "></td>
              </tr>
            </tbody>
          </table>
        </div>
        <p class="text-right" v-show="showOrderStats">
           <router-link :to=" `/dashboard/orders?store_id=${storeInformation.search_store_id ||storeInformation.id}` "
              class="btn btn-sm btn-link p-0" v-text="$t('general.viewAll')">
            </router-link>
        </p>
      </div>
    </div>

    <div class="card">
      <div class="card-body">
        <div class="row align-items-center">
          <div class="col">
            <h6 class="m-0">
              <span v-text="$t('dashboard.store.products')"></span>
              <span v-text=" ' (' + ((storeInformation.stadistics.products.active + storeInformation.stadistics.products.inactive) || 0 ) + ')' "></span>
            </h6>
          </div>
          <div class="col-auto">
            <button class="btn btn-outline-success circle-25" @click="showProductsStats = !showProductsStats">
              <i class="fa fa-eye fa-sm" v-if="!showProductsStats"></i>
              <i class="fa fa-eye-slash fa-sm" v-else></i>
            </button>
          </div>
        </div>
        <div class="animated fadeIn" v-show="showProductsStats">
          <table class="table table-striped table-bordered mt-2">
            <tbody>
              <tr>
                <td class="col" v-text="$t('general.online')"> </td>
                <td class="text-center" v-text=" storeInformation.stadistics.products.active || 0 "></td>
              </tr>
              <tr>
                <td v-text="$t('general.offline')"> </td>
                <td class="text-center" v-text=" storeInformation.stadistics.products.inactive || 0 "></td>
              </tr>
              <tr>
                <td v-text="$t('dashboard.store.Incompleted')"> </td>
                <td class="text-center" v-text=" storeInformation.stadistics.products.incomplete || 0 "></td>
              </tr>
            </tbody>
          </table>
        </div>
        <p class="text-right" v-show="showProductsStats">
          <router-link
              :to=" `/dashboard/products?store_id=${storeInformation.search_store_id ||storeInformation.id}` "
              class="btn btn-sm btn-link p-0" v-text="$t('general.viewAll')">
            </router-link>
        </p>
      </div>
    </div>

    <div class="card">
      <div class="card-body" v-show="storeInformation.ecommerce != 'Ecart' ">
        <div class="row align-items-center ">
          <div class="col">
            <p class="f-w-500">
              <span v-text="$t('dashboard.store.syncStore')"></span>
            </p>
          </div>
          <div class="col-auto ml-auto">
            <button class="btn btn-success  circle-25 " @click="fnSyncStore(storeInformation)">
              <i class="fa fa-sync fa-sm"></i>
            </button>
          </div>
        </div>
        <p class="m-0">
          <small v-text="$t('dashboard.store.syncProductsMessage')"> </small>
        </p>
      </div>
      <div class="card-footer" v-show="storeInformation.ecommerce != 'Ecart'">
        <button class=" btn btn-success w-100 rounded-pill" @click="fnConvertStore() "
          v-text="$t('general.button.convert')">
        </button>
      </div>
      <div class=" " v-show="storeInformation.ecommerce == 'Ecart' "  :class=" storeInformation.ecommerce != 'Ecart' ? 'card-footer' : 'card-body' ">
        <router-link :to=" `/dashboard/new-product/${storeInformation.search_store_id ||storeInformation.id}` "
          class="btn btn-success btn w-100 rounded-pill" v-text="$t('general.button.newProduct')">
        </router-link>
      </div>

    </div>
  </div>
</template>

<script>
  import {
    mapActions,
    mapMutations,
    mapState
  } from 'vuex';
  export default {
    data() {
      return {
        showOrderStats: false,
        showProductsStats: false,
        convertModal: false,
      }
    },
    computed: {
      ...mapState('EcartStore', ['storeInformation', 'addressesList', 'integration']),
      ...mapState('session', ['user']),
    },
    methods: {
      ...mapActions('EcartStore', ['fnSyncStore', 'fnApiConvertStore']),
      fnConvertStore() {
        const body = this.$createElement('div', {
          domProps: {
            innerHTML: `<p><strong class="">${this.storeInformation.name}</strong></p> <hr>` +
              this.$tc('messages.convertStore', 1, {
                ecommerce: `<strong class="text-info">${this.storeInformation.ecommerce}</strong>`
              })
          }
        });
        this.$bvModal.msgBoxConfirm(body, {
            title: this.$t('general.button.confirmation'),
            size: 'md',
            okVariant: 'success',
            okTitle: this.$t('general.button.agree'),
            cancelTitle: this.$t('general.button.cancel'),
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true
          })
          .then(value => {
            if (value) {
              this.fnApiConvertStore();
            }
          })
          .catch(err => {})
      }
    }
  }
</script>

<style>

</style>