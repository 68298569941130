<template>
  <div>
    <div class="">
      <div class=" form-rounded-inputs">
        <v-observer class=" " tag="form" ref="formStoreInfo" @submit.prevent="fnValidateInformation()">
          <div class="row">

            <!-- <div class="col-12 mb-2">
              <p class="text-left">
                <small v-text=" $t('dashboard.store.message') "></small> <br>
                <small class="text-muted" v-if="!integration" v-text="$t('dashboard.store.messages.currency') "></small>
              </p>
            </div> -->

            <div class="col-12 col-xl-8">
              <div class="row mb-3 align-items-center">
                <div class="col-auto pr-0 ">
                  <img :src="storeInformation.image" class=" img-cover-60 border " v-if="storeInformation.image">
                  <img v-image=" storeInformation.image ? storeInformation.image : storeInformation.image_url"
                    class=" img-cover-60 border " v-else>
                </div>
                <div class="col ">
                  <div class="form-group mb-0">
                    <label for="" v-text=" $t('dashboard.store.storeImage') ">
                    </label>
                    <div class="custom-file">
                      <input type="file" accept="image/*" @change=" fnStoreImage( $event  ) " class="custom-file-input"
                        id="validatedCustomFile">
                      <label class="custom-file-label  border border-dark-gray " for="validatedCustomFile"
                        v-text=" (storeInformation.image != null ) ? $t('general.form.newImage') : $t('general.form.chooseImage') "></label>
                      <div class="invalid-feedback"> 123 </div>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <p class="animated fadeIn bg-danger rounded mt-2 " v-show="maxSizeImageAlert">
                    <i class=" fa fa-exclamation-circle mx-1"></i> <span
                      v-text="$tc('alertMessages.maxSize', 1 , {size:2})"></span>
                  </p>
                </div>
              </div>
            </div>
            <div class="col-12 ">
              <div class="row justify-content-between ">
                <div class="col-12 col-sm-6 col-xl-4 ">
                  <v-validation rules="required|min:3|max:30" v-slot="{ errors }"
                    :name=" $t('dashboard.store.storeName') ">
                    <div class="form-group">
                      <label for="" class="w-100 " v-text=" $t('dashboard.store.storeName') "> </label>
                      <div class="input-group ">
                        <input type="text" class="form-control" v-model="storeInformation.name"
                          placeholder="Example Store" :class=" { 'is-invalid':errors.length > 0 } ">
                        <div class="input-group-append">
                          <span class="input-group-text"> <strong>
                              <i class=" fa fa-store "></i>
                            </strong> </span>
                        </div>
                        <div class="invalid-feedback" v-if="errors.length > 0">
                          {{ errors[0] }}
                        </div>
                      </div>
                    </div>
                  </v-validation>

                </div>

                <div class="col-12 col-sm-6 col-xl-4 ">
                  <v-validation rules="required|email" v-slot="{ errors }" :name=" $t('dashboard.store.storeEmail') ">
                    <div class="form-group">
                      <label for="" v-text=" $t('dashboard.store.storeEmail') "> </label>

                      <div class="input-group ">
                        <input type="email" v-model="storeInformation.email" class="form-control"
                          placeholder="example@ecart.com" :class=" { 'is-invalid':errors.length > 0 } ">
                        <div class="input-group-append">
                          <span class="input-group-text"> <strong>
                              <i class=" fa fa-envelope "></i> </strong>
                          </span>
                        </div>
                        <div class="invalid-feedback" v-if="errors.length > 0">
                          {{ errors[0] }}
                        </div>
                      </div>

                    </div>
                  </v-validation>
                </div>

                <div class="col-12 col-sm-6 col-xl-4 ">
                  <v-validation rules="required|min:8|max:10" v-slot="{ errors }"
                    :name=" $t('dashboard.store.storePhone') ">
                    <div class="form-group">
                      <label for="" v-text=" $t('dashboard.store.storePhone') "> </label>
                      <div class="input-group ">
                        <the-mask :mask="['##-####-####']" placeholder="81-8000-800" v-model="storeInformation.phone "
                          class="form-control" :class=" { 'is-invalid':errors.length > 0 } " />
                        <div class="input-group-append">
                          <span class="input-group-text"> <strong>
                              <i class=" fa fa-phone "></i> </strong> </span>
                        </div>
                        <div class="invalid-feedback" v-if="errors.length > 0">
                          {{ errors[0] }}
                        </div>
                      </div>
                    </div>
                  </v-validation>
                </div>

                <div class="col-12 col-sm-6 col-xl-4">
                  <v-validation rules="required|min_value:8.5|max_value:25" v-slot="{ errors }"
                    :name=" $t('dashboard.store.ecartCommission') ">
                    <div class="form-group">
                      <label for="" class=" w-100 ">
                        <span v-text=" $t('dashboard.store.ecartCommission') "></span>
                        <i class=" fa fa-question-circle text-info ml-1 "
                          v-b-popover.hover.top="$tc('dashboard.store.commisionMessage',1,{commission:storeInformation.settings.commission })"></i>
                      </label>

                      <div class="input-group ">
                        <input type="number" min="8.5" max="25" step="0.5"
                          v-model="storeInformation.settings.commission" class="form-control"
                          :class=" { 'is-invalid':errors.length > 0 } " placeholder="25%">
                        <div class="input-group-append">
                          <span class="input-group-text"> <strong>
                              <i class=" fa fa-percent "></i> </strong>
                          </span>
                        </div>
                        <div class="invalid-feedback" v-if="errors.length > 0">
                          {{ errors[0] }}
                        </div>
                      </div>

                    </div>
                  </v-validation>
                </div>

                <div class="col-12 col-sm-6 col-xl-4">
                  <v-validation rules="required" v-slot="{ errors }" :name=" $t('dashboard.store.productsLanguage') ">
                    <div class="form-group">
                      <label>
                        <span v-text="$t('dashboard.store.productsLanguage')"></span>
                        <i class=" fa fa-question-circle text-info ml-1 "
                          v-b-popover.hover.top="$t('messages.productsLanguage')"></i>
                      </label>
                      <div class="input-group " :class=" { 'is-invalid ':errors.length > 0 } ">
                        <select class="custom-select " :class=" { 'is-invalid ':errors.length > 0 } "
                          :disabled=' !integration ' v-model="storeInformation.settings.language">
                          <option :value="null" selected v-text=" $t('general.form.select') "> </option>
                          <option value="es-ES">Español</option>
                          <option value="en-US">Ingles</option>
                        </select>
                        <div class="input-group-append">
                          <label class="input-group-text"> <i class="fa fa-language"></i> </label>
                        </div>
                      </div>
                      <div class="invalid-feedback" v-if="errors.length > 0">
                        {{ errors[0] }}
                      </div>
                    </div>
                  </v-validation>
                </div>

                <div class="col-12 col-sm-6 col-xl-4">

                  <v-validation rules="required" v-slot="{ errors }" :name=" $t('dashboard.store.storeCurrency') ">
                    <div class="form-group">
                      <label>
                        <span v-text="$t('dashboard.store.storeCurrency')"></span>
                        <i class=" fa fa-question-circle text-info ml-1 "
                          v-b-popover.hover.top="$t('messages.productsCurrency')"></i>
                      </label>
                      <div class="input-group " :class=" { 'is-invalid ':errors.length > 0 } ">
                        <select class="custom-select " :class=" { 'is-invalid ':errors.length > 0 } "
                          :disabled=' !integration ' v-model="storeInformation.currency">
                          <option :value="null" selected v-text=" $t('general.form.select') "> </option>
                          <option :value="currency" v-for=" (currency,index) in CONST.CURRENCIES " :key="index"
                            v-text=" currency "> </option>
                        </select>
                        <div class="input-group-append">
                          <label class="input-group-text"> <i class="fa fa-dollar-sign"></i> </label>
                        </div>
                      </div>
                      <div class="invalid-feedback" v-if="errors.length > 0">
                        {{ errors[0] }}
                      </div>
                    </div>
                  </v-validation>

                </div>
              </div>

            </div>

            <div class="col-12 mt-2 ">
              <v-validation rules="required|min:8|max:500" v-slot="{ errors }"
                :name=" $t('dashboard.store.storeDescription') ">
                <div class="form-group h-100">
                  <label for="" v-text=" $t('dashboard.store.storeDescription') "> </label>
                  <textarea name="" id="" cols="30" rows="5" maxlength="500" class="form-control h-75"
                    v-model="storeInformation.description" :class=" { 'is-invalid':errors.length > 0 } "></textarea>
                  <div class="invalid-feedback" v-if="errors.length > 0">
                    {{ errors[0] }}
                  </div>
                </div>
              </v-validation>
            </div>



            <div class="col-auto ml-auto">
              <button class=" btn btn-success " :disabled="loading.data">
                <span v-text=" integration ?  $t('general.button.next') : $t('general.button.update') "
                  v-if=" !loading.data "></span>
                <i class="fa fa-spinner fa-spin " v-else></i>
              </button>
            </div>
          </div>
        </v-observer>
      </div>
    </div>

  </div>
</template>

<script>
  import {
    mapActions,
    mapState
  } from 'vuex'
  export default {
    data() {
      return {
        maxSizeImageAlert: false,
      }
    },
    computed: {
      ...mapState('EcartStore', ['storeInformation', 'integration', 'loading']),
    },
    methods: {
      ...mapActions('EcartStore', ['fnApiUpdateStore']),

      async fnValidateInformation() {
        if (await this.$refs['formStoreInfo'].validate()) {
          await this.fnApiUpdateStore();
          if (this.storeInformation.image_url) {
            window.URL.revokeObjectURL(this.storeInformation.image_url);
          }
        }
      },

      fnUpdateAddress(index) {
        if (this.addresses[index] && this.addresses[index].id) {
          this.modalAddress = true;
          let tempAddress = this.$lodash.cloneDeep(this.addresses[index]);
          this.storeAddress = {
            id: tempAddress.id,
            address_name: tempAddress.address_name,
            first_name: tempAddress.first_name || this.user.first_name,
            last_name: tempAddress.last_name || this.user.last_name,
            address1: tempAddress.address1,
            address2: tempAddress.address2,
            country: {
              code: tempAddress.country.code || this.user.country,
            },
            city: tempAddress.city,
            postal_code: tempAddress.postal_code,
            phone: tempAddress.phone,
            state: {},
          };
          if (tempAddress.state.code) {
            this.storeAddress.state.code = tempAddress.state.code;
            this.fnGetProvinces(tempAddress.state.code);

          } else {
            this.storeAddress.state.name = tempAddress.state.name;
          }
          this.fnGetStates(this.storeAddress.country.code);
        } else {
          this.fnPreloadAddress();
        }
      },

      fnStoreImage(event) {
        this.maxSizeImageAlert = false;
        if (event.target.files.length > 0) {
          var file = event.target.files[0];
          if (file.size < 2000000) {
            var reader = new FileReader();
            var baseString;
            reader.onloadend = ((event) => {
              this.storeInformation.image = event.target.result;
            });
            reader.readAsDataURL(file);
          } else {
            this.maxSizeImageAlert = true;
          }
        }
      },
    },


  }
</script>

<style>

</style>